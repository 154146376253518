[margin] {
  margin: 12px;
}

[margin-t10] {
  margin-top: 10px;
}

[margin-b5] {
  margin-bottom: 5px;
}

[margin-b10] {
  margin-bottom: 10px;
}

[gdx-padding] {
  padding: 16px;
}

[vertical-padding] {
  padding: 8px 0;
}

[horizontal-padding] {
  padding: 0 8px;
}

[padding] {
  padding: 18px;
}

[no-margin] {
  margin: 0;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 1rem;
}

.m-l-10 {
  margin-left: 10px !important;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.m-0 {
  margin: 0;
}
.m-px {
  margin: 1px;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-7 {
  margin: 1.75rem;
}
.m-8 {
  margin: 2rem;
}
.m-9 {
  margin: 2.25rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-11 {
  margin: 2.75rem;
}
.m-12 {
  margin: 3rem;
}
.m-14 {
  margin: 3.5rem;
}
.m-16 {
  margin: 4rem;
}
.m-20 {
  margin: 5rem;
}
.m-24 {
  margin: 6rem;
}
.m-28 {
  margin: 7rem;
}
.m-32 {
  margin: 8rem;
}
.m-36 {
  margin: 9rem;
}
.m-40 {
  margin: 10rem;
}
.m-44 {
  margin: 11rem;
}
.m-48 {
  margin: 12rem;
}
.m-52 {
  margin: 13rem;
}
.m-56 {
  margin: 14rem;
}
.m-60 {
  margin: 15rem;
}
.m-64 {
  margin: 16rem;
}
.m-72 {
  margin: 18rem;
}
.m-80 {
  margin: 20rem;
}
.m-96 {
  margin: 24rem;
}
.m-auto {
  margin: auto;
}
