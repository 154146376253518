$color-white: #ffff;
$color-black: var(--ion-color-light-contrast);
$color-dark: var(--ion-color-dark);
$color-primary: var(--ion-color-primary);
$color-medium: var(--ion-color-medium);
$bgcontent: var(--gdx-color-bgcontent);

.color-header {
  color: #424242 !important;
}
.color-primary {
  color: $color-primary !important;
}
.color-medium {
  color: $color-medium !important;
}
.color-dark {
  color: $color-dark !important;
}
