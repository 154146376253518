// start header style

.header-primary {
  ion-toolbar {
    background: var(--ion-color-primary);
    --ion-toolbar-background: var(--ion-color-primary) !important;
  }
}

.header-white {
  ion-toolbar {
    background-color: white !important;
    --ion-toolbar-background: white !important;
  }
}

ion-header {
  --ion-toolbar-background: white !important;
  background-color: white !important;
  margin: 0 auto;

  ion-toolbar {
    margin: 0 auto;
    --background: transparent !important;

    @media only screen and (min-width: shared-var.$primary-desktop-width) {
      max-width: 430px !important;
    }

    @media only screen and (min-width: shared-var.$primary-tablet-width) and (max-width: shared-var.$primary-desktop-width) {
      max-width: 100%;
    }

    @media only screen and (max-width: shared-var.$primary-max-tablet-width) {
      max-width: 430px !important;
    }
  }
}

.header-md::after {
  @media screen and (min-width: shared-var.$primary-desktop-width) {
    width: unset;
  }
}

.container {
  @media only screen and (min-width: shared-var.$primary-desktop-width) {
    max-width: 430px !important;
  }

  @media only screen and (min-width: shared-var.$primary-tablet-width) and (max-width: shared-var.$primary-desktop-width) {
    max-width: 90%;
  }

  @media only screen and (max-width: shared-var.$primary-max-tablet-width) {
    max-width: 430px !important;
  }

  margin: 0 auto;
}

.container-mini {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
}

ion-content .container-mini {
  min-height: calc(100vh - 60px - 56px);
  //margin-top: 60px; // See https://docs.google.com/document/d/162E9l_4M6o8pbERE6dhfvzKtHAOBQj2JenKaA2w8f60/edit
  margin-top: 10px;
  background: #fff;
  padding: 15px 10px 60px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 3px 20px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}

ion-content {
  ion-card[gdx-content] {
    display: table-header-group;
  }

  .action-top {
    position: sticky;
    top: 56px;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    padding: 6px 16px;
    border-radius: 6px;
    transition: background-color 200ms;

    .action-buttons {
      display: flex;
      place-content: flex-end;
      align-items: flex-end;
      flex-wrap: wrap;
    }

    .segment-wrapper {
      // wrap button to top if tab and info got pressed being less than 40% width
      flex: 1 0 50%;
      display: flex;
      flex-direction: column;

      .info {
        display: flex;
        // min-height = ion-button {min-height + margin-vertical}
        min-height: 48px;
        align-items: center;

        transition: height 200ms, min-height 200ms;
        overflow: hidden;

        color: grey;
      }

      // tab page
      ion-segment {
        flex: 1 1 auto;

        ion-segment-button {
          flex: 0 0 auto;
          text-transform: capitalize;
          --margin-start: 0;
          --margin-end: 0;
        }
      }
    }
  }

  ion-card {
    border-radius: 10px;
  }

  *:not(ion-card) {
    --ion-background-color: transparent;
  }
}

[no-scroll] {
  --overflow: hidden;
}

.ui-cover {
  width: 100%;
  max-height: 380px;
  object-fit: cover;
}

.can-go-back ion-header ion-back-button {
  display: block;
}

ion-app .app-header {
  background-color: rgba(242, 245, 248, 0.9);
}

ion-content.scrolled {

  .action-top,
  ion-header ion-toolbar {
    background-color: rgba(242, 245, 248, 0.9);
  }
}

ion-footer {
  position: sticky;
  bottom: 0;

  @media screen and (min-width: shared-var.$primary-desktop-width) {
    width: calc(100% - 18px);
  }

  --ion-toolbar-background: white !important;
  background-color: white !important;

  ion-toolbar {
    @media only screen and (min-width: shared-var.$primary-desktop-width) {
      max-width: 430px !important;
    }

    @media only screen and (min-width: shared-var.$primary-tablet-width) and (max-width: shared-var.$primary-desktop-width) {
      max-width: 90%;
    }

    @media only screen and (max-width: shared-var.$primary-max-tablet-width) {
      max-width: 430px !important;
    }

    --background: var(--gdx-color-bgcard);
    border-top: 1px solid lightgrey;
    margin: 0 auto;
  }
}

.footer-md::before {
  width: unset;
}

.header-md::after {
  @media screen and (min-width: shared-var.$primary-desktop-width) {
    width: unset;
  }
}

.footer-md::before {
  width: unset;
}

// #region tablet landscape 1024px - 1279px
@media only screen and (min-width: 1024px) {}

// #endregion

// #region desktop >= 1280px
@media only screen and (min-width: 1280px) {}

@media (orientation: portrait) {
  ion-header {
    padding-top: constant(safe-area-inset-top); //ios 11.2
    padding-top: env(safe-area-inset-top); //ios 11.1
  }

  ion-header {
    ion-toolbar.ios {
      padding-top: 0px !important;
    }
  }

  ion-content {
    padding-top: constant(safe-area-inset-top); //ios 11.2
    padding-top: env(safe-area-inset-top); //ios 11.1
  }
}