@use "theme/shared-var.scss";

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/fontface.scss";
@import "theme/colors.scss";

@import "theme/typography.scss";
@import "theme/component-generic.scss";
@import "theme/flexbox-grid.scss";
@import "theme/layout.scss";
@import "theme/sizing.scss";
@import "theme/spacing.scss";
@import "theme/borders.scss";
@import "theme/interactivity.scss";
@import "theme/transform.scss";

// dark mode transition
// * {
//   $duration: 400ms;
//   transition: background-color $duration, background $duration, box-shadow $duration, color $duration,
//     border-top $duration, border-bottom $duration, border-right $duration, border-left $duration, --background $duration;
// }

/* #region sttorybook bug */
html:not(.hydrated) body {
  display: block !important;
}

ion-modal.gdx-alert .ion-page {
  width: 100% !important;
}

ion-nav {
  border-radius: unset !important;
}

//.pin-lock-modal {
//  z-index: 99999!important;
//}
.balance-information-modal {
  z-index: 19999 !important;
}

.pin-lock-modal,
.balance-information-modal {
  --width: 100%;
  --height: 100%;

  .modal-wrapper {
    --height: 100%;
    --width: 100%;
    background: transparent !important;
    border-radius: unset;
    max-width: 430px !important;
    margin: 0 auto;
  }
}

.alert-content {
  padding-right: 16px !important;
}

[badge-dot-benefits] {
  background-color: var(--ion-color-danger);
  border-radius: 50px;
  position: absolute;
  right: 3.1%;
  top: 12%;
  color: white;
  font-size: 9.2px;
  font-weight: bold;
  padding: 2px 6px;
  width: unset;
  height: unset;
}

/**
 *  Badge css if accessed from GD
 */
[badge-dot-benefits-gd] {
  background-color: var(--ion-color-danger);
  border-radius: 50px;
  position: absolute;
  top: 0.6em;
  color: white;
  font-size: 9.2px;
  font-weight: bold;
  padding: 2px 6px;
  margin-left: 1em;
  width: unset;
  height: unset;
}

ion-app,
body,
.ion-page-benefits {
  background: transparent !important;
}

ion-modal.modal-default {
  --width: 100% !important;
  --max-width: 100% !important;
  --background: transparent;
}

ion-modal.gdx-alert .ion-page {
  background: white;
}

.header-benefits {
  --background: var(--ion-color-primary) !important;

  ion-button {
    min-width: 50px;
    height: auto;
  }

  ion-icon {
    font-size: 24px;
    color: white !important;
  }

  ion-label {
    display: flex;
    align-items: center;
    color: white;
    font-size: 20px !important;
  }
}

.popup-banner {
  position: absolute !important;
  z-index: -1;
}


.input-highlight.sc-ion-input-md {
  --border-color: var(--ion-color-primary) !important;
  background: var(--ion-color-primary) !important;
}

.has-focus.sc-ion-input-md-h input.sc-ion-input-md {
  caret-color: var(--ion-color-primary) !important;
}