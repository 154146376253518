[centered] {
  display: flex;
  justify-content: center;
  align-items: center;
}

[col-12] {
  flex: 0 0 100%;
  width: 100%;
  max-width: 100%;
}

[col-6] {
  flex: 0 0 50%;
  width: 50%;
  max-width: 50%;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}
