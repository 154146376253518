/* ============== Generic Components ================ */

@mixin ion-button-size($height, $min-width, $font-size) {
  height: $height;
  min-width: $min-width;
  font-size: $font-size;
  width: fit-content;
}

ion-button {
  --border-radius: 10px;
  --color: var(--ion-color-light);
  text-transform: capitalize;
  box-shadow: none;
  --box-shadow: none;
  // font-weight: bold;

  // to make 3 button fit on iPhone 5/SE, min-width: 92px;
  @include ion-button-size(40px, 92px, 14px); // small default;;;
  width: unset;
}

ion-button[large] {
  @include ion-button-size(48px, 108px, 16px);
}

ion-button[medium] {
  @include ion-button-size(40px, 92px, 14px);
}

ion-button[small] {
  @include ion-button-size(32px, 83px, 12px);
}

ion-button[block] {
  @include ion-button-size(32px, 95%, 12px);
}

ion-button {
  ion-label {
    color: white;
  }
}

ion-checkbox {
  --size: 20px;
  --checkmark-width: 6px;
}

ion-radio {
  --border-width: 1.6px;
}

ion-toggle {
  width: 41px;
  height: 26px;
  --handle-spacing: 0;
  --handle-height: 92%;
  --handle-width: 24px;
}

ion-input {
  --color: var(--ion-color-dark) !important;
}

ion-list-header > ion-label {
  font-weight: bold;
  color: var(--ion-color-dark);
}

ion-item > svg-icon {
  margin-right: 10px;
}

ion-select:part(text) {
  color: var(--ion-color-medium);
}

ion-label {
  color: var(--ion-color-dark);
}

ion-ripple-effect {
  margin: 0 !important;
}
