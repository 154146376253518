/* #region: ===== Mediascreen ================ */

// pakai min-width
$primary-mobile-width: 576px;
$primary-tablet-width: 768px;
$primary-max-tablet-width: 767px;
$primary-desktop-width: 1024px;

$secondary-lite-width: 324px;
$secondary-mobile-width: 360px;
$secondary-tablet-width: 960px;
$secondary-desktop-width: 1280px;

/* #endregion: == Mediascreen ================ */

// MIXIN //

@mixin absolute-box($top: unset, $right: unset, $bottom: unset, $left: unset) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin shape-box($width: unset, $height: unset, $radius: 0) {
  width: $width;
  height: $height;
  border-radius: $radius;
}

@mixin font-setting($size, $color: unset, $weight: unset) {
  font-size: $size;
  color: $color;
  font-weight: $weight;
}
