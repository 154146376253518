$font-base: (
  'p[giant-text]': 20px,
  'ion-label[giant-text]': 20px,
  'p[large-text]': 16px,
  'ion-label[large-text]': 16px,
  'p[medium-text]': 14px,
  'ion-label[medium-text]': 14px,
  'p[small-text]': 12px,
  'ion-label[small-text]': 12px,
  'p[micro-text]': 10px,
  'ion-label[micro-text]': 10px,
  'p': 14px,
  'ion-label': 14px,
);

@each $name, $size in $font-base {
  #{$name} {
    font-size: $size !important;
  }
}

[bold] {
  font-weight: bold;
}

[semi-bold] {
  font-weight: 400;
}

[italic] {
  font-style: italic;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-bold-600 {
  font-weight: 600;
}

[text-center] {
  text-align: center;
  align-self: center;
}

[icon-small] {
  font-size: 10px;
}

[icon-medium] {
  font-size: 12px;
}

[icon-large] {
  font-size: 18px;
}

[line-through] {
  text-decoration: line-through;
}

.font-thin {
  font-weight: 100;
}
.font-extralight {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}
.text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem !important;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem !important;
  line-height: 1;
}
.text-6xl {
  font-size: 3.75rem !important;
  line-height: 1;
}
.text-7xl {
  font-size: 4.5rem !important;
  line-height: 1;
}
.text-8xl {
  font-size: 6rem !important;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem !important;
  line-height: 1;
}
